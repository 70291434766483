<template>
  <div class="search-and-results">
    <!-- 顶部输入栏 -->
    <el-form :model="searchParams" ref="searchForm" class="search-bar" :inline="true">
      <el-row>
        <el-form-item label="设备列表">
          <el-select placeholder="选择设备" v-model="searchParams.deviceid" clearable filterable>
            <el-option-group v-for="group in deviceList" :key="group.label" :label="group.label">
              <el-option v-for="item in group.options" :key="item.deviceid" :label="item.devicename"
                :value="item.deviceid"></el-option>
            </el-option-group>
          </el-select>
        </el-form-item>

        <el-form-item label="起止时间">
          <el-date-picker v-model="searchParams.daterange" type="datetimerange" format="yyyy-MM-dd HH:mm"
            range-separator="至" start-placeholder="开始时间" end-placeholder="结束时间"></el-date-picker>
        </el-form-item>

        <el-form-item label="搜索关键词">
          <el-input v-model="searchParams.keyword" placeholder="订单编号，发/收货单位" clearable></el-input>
        </el-form-item>
      </el-row>
      <el-row>
        <el-button type="primary" @click="search">查询</el-button>
        <el-button type="success" @click="openModal('add')">新建订单</el-button>
      </el-row>
    </el-form>

    <!-- 查询结果表 -->
    <el-table :data="orders" class="results-table">
      <el-table-column prop="OrderNumber" label="订单编号"></el-table-column>
      <el-table-column prop="Device.DeviceName" label="关联设备"></el-table-column>
      <el-table-column prop="Device.DeviceCode" label="设备编号"></el-table-column>
      <el-table-column prop="Shipper" label="发货单位"></el-table-column>
      <el-table-column prop="Receiver" label="收货单位"></el-table-column>
      <el-table-column prop="StartTime" min-width='75' label="开始时间" :formatter="formatTime"></el-table-column>
      <el-table-column prop="StopTime" min-width='75'  label="结束时间" :formatter="formatTime"></el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.StopTime" type="success" effect="dark">
            已完结
          </el-tag>
          <el-button v-if="!scope.row.StopTime" type="text" @click="openStopTimeModal(scope.row.Id)">停止</el-button>
          <el-button v-if="!scope.row.StopTime" type="text" @click="editOrder(scope.row)">编辑</el-button>
          <el-button v-if="roletype<=4" style="color: #f56c6c;margin-left:10px;width:40px" type="text" icon="el-icon-delete"
            @click="deleteOrder(scope.row.Id)"></el-button>
          <el-button style="margin-left:10px;width:40px" type="text" plain effect="dark"
            @click="jumpToHistory(scope.row)" icon="el-icon-s-data"></el-button>
        </template>
      </el-table-column>
    </el-table>
    <!--工具条-->
    <el-col :span="24" class="toolbar">
      <el-pagination layout="prev, pager, next, jumper" @current-change="handleCurrentChange" :page-size="20"
        :total="total" :current-page="page" style="float:right;"></el-pagination>
    </el-col>
    <!-- 订单 Modal -->
         <!-- 订单 Modal -->
    <el-dialog :title="dialogTitle" :visible.sync="isModalOpen" width="50%">
      <el-form ref="orderForm" :model="newOrder" class="dialog-content" :rules="orderFormRules" label-width="100px">
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="关联设备" prop="deviceid" required>
              <el-select placeholder="选择设备" v-model="newOrder.deviceid" clearable filterable style="width: 100%">
                <el-option-group v-for="group in deviceList" :key="group.label" :label="group.label">
                  <el-option v-for="item in group.options" :key="item.deviceid" :label="item.devicename"
                    :value="item.deviceid"></el-option>
                </el-option-group>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="订单编号" prop="ordernumber" required>
              <el-input v-model="newOrder.ordernumber"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        
        <el-form-item label="开始时间" prop="starttime" required>
          <el-date-picker v-model="newOrder.starttime" type="datetime" placeholder="选择日期时间" style="width: 100%"></el-date-picker>
        </el-form-item>

        <el-collapse v-model="activeCollapseNames" class="custom-collapse">
          <el-collapse-item name="shipper">
            <template slot="title">
              <div class="collapse-header">
                <i class="el-icon-truck"></i> 发货方信息
                <span v-if="newOrder.shipper" class="collapse-summary">{{ newOrder.shipper }}</span>
              </div>
            </template>
            <el-form-item label="选择发货方">
              <el-select v-model="newOrder.shipperid" placeholder="请选择发货方" @change="setShipperInfo" filterable clearable style="width: 100%">
                <el-option v-for="contact in enterprises" :key="contact.Id"
                  :label="contact.Name + ' (' + contact.Code + ')'" :value="contact.Id"></el-option>
              </el-select>
            </el-form-item>
            <el-row :gutter="20">
              <el-col :span="12">
                <el-form-item label="发货单位">
                  <el-input v-model="newOrder.shipper" placeholder="发货单位" clearable></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="社会信用码">
                  <el-input v-model="newOrder.shippercode" placeholder="统一社会信用代码" clearable></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-form-item label="发货地址">
              <el-input v-model="newOrder.shipperaddress" placeholder="发货单位地址" clearable></el-input>
            </el-form-item>
          </el-collapse-item>

          <el-collapse-item name="receiver">
            <template slot="title">
              <div class="collapse-header">
                <i class="el-icon-location"></i> 收货方信息
                <span v-if="newOrder.receiver" class="collapse-summary">{{ newOrder.receiver }}</span>
              </div>
            </template>
            <el-form-item label="选择收货方">
              <el-select v-model="newOrder.receiverid" placeholder="请选择收货方" @change="setReceiverInfo" filterable clearable style="width: 100%">
                <el-option v-for="contact in enterprises" :key="contact.Id"
                  :label="contact.Name + ' (' + contact.Code + ')'" :value="contact.Id"></el-option>
              </el-select>
            </el-form-item>
            <el-row :gutter="20">
              <el-col :span="12">
                <el-form-item label="收货单位">
                  <el-input v-model="newOrder.receiver" placeholder="收货单位" clearable></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="社会信用码">
                  <el-input v-model="newOrder.receivercode" placeholder="统一社会信用代码" clearable></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-form-item label="收货地址">
              <el-input v-model="newOrder.receiveraddress" placeholder="收货单位地址" clearable></el-input>
            </el-form-item>
          </el-collapse-item>
        </el-collapse>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closeModal">取 消</el-button>
        <el-button type="primary" @click="orderSubmit">{{ currentAction === 'edit' ? '更 新' : '确 定' }}</el-button>
      </span>
    </el-dialog>

    <!-- 停止订单 Modal -->
    <el-dialog title="设置运单结束时间" :visible.sync="isStopTimeModalOpen" width="20%">
      <el-form class="dialog-content">
        <el-form-item label="结束时间">
          <el-date-picker v-model="stopTime" type="datetime" placeholder="选择日期时间"></el-date-picker>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="isStopTimeModalOpen = 0">取消</el-button>
        <el-button type="primary" @click="setStopTime">确定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import moment from 'moment'

export default {
  data() {
    return {
      activeCollapseNames: [], 
      searchParams: {
        deviceid: null,
        daterange: null,
        keyword: ''
      },
      orders: [],
      deviceList: [],
      newOrder: {
        ordernumber: '',
        starttime: '',
        shipper: '',
        shippercode: '',
        shipperaddress: '',
        receiver: '',
        receivercode: '',
        receiveraddress: '',
        shipperid: null,
        receiverid: null,
      },
      templateOrder: {
        ordernumber: '',
        starttime: '',
        shipper: '',
        shippercode: '',
        shipperaddress: '',
        receiver: '',
        receivercode: '',
        receiveraddress: '',
        shipperid: null,
        receiverid: null,
      },
      enterprises: [],
      isModalOpen: false,
      page: 1,
      pageSize: 20,
      total: 0,
      currentAction: '',
      dialogTitle: '',
      isStopTimeModalOpen: false,
      stopTimeId: 0,
      stopTime: null,
      onlyNotEnd: true,
      orderFormRules: {
        deviceid: [{ required: true, message: "请选择关联设备", trigger: "blur" }],
        ordernumber: [{ required: true, message: "请输入订单编号", trigger: "blur" }],
        starttime: [{ required: true, message: "请选择开始时间", trigger: "blur" }]
      },
    };
  },
  computed: {
    ...mapGetters(["roletype", "customerid"]),
  },
  methods: {
    formatTime: (row, column) => {
      if (!row[column['property']]) return '运输中';
      return new Date(row[column['property']]).format("yyyy-mm-dd HH:MM:ss");
    },
    openStopTimeModal(id) {
      this.stopTimeId = id;
      this.isStopTimeModalOpen = true;
      this.stopTime = new Date();
    },
    setStopTime() {
      this.axios
        .post("/delivery/updatedeliverorder", { customerid: this.customerid, id: this.stopTimeId, stoptime: this.stopTime })
        .then(res => {
          if (res.data.code !== 0) {
            this.$message.error(res.data.message);
            return;
          }
          this.$message.success('订单成功停止');
          this.search();
          this.isStopTimeModalOpen = false;
        });
    },
    mountEnterprises() {
      this.axios
        .post("/delivery/enterpriselist", { customerid: this.customerid, keyword: '' })
        .then(res => {
          this.enterprises = res.data.values.rows;
        });
    },
    mountCloudDevices() {
      this.axios
        .post("/datacenter/getdeviceslist", { customerid: this.customerid })
        .then(res => {
          /** 查询结果以deviceid为key 去重 */
          let devicesMap = new Map();
          res.data.values.deviceslist.map(c => {
            if (c.devicetype > 2) {
              devicesMap.set(c.deviceid, c);
            }
          });
          let devices = [...devicesMap.values()];
          /** 查询结果以deviceid为key 去重 END */
          let newArray = [
            { label: "保温箱", options: [] },
            { label: "冷藏车", options: [] },
            { label: "STL冷链箱", options: [] }
          ];
          devices.map(device => {
            if (device.devicetype == 3) {
              newArray[0].options.push(device);
            } else if (device.devicetype == 6) {
              newArray[2].options.push(device);
            } else {
              newArray[1].options.push(device);
            }
          });
          this.deviceList = newArray;
        });
    },
    search() {
      const params = {
        customerid: this.customerid,
        keyword: this.searchParams.keyword,
        deviceid: this.searchParams.deviceid,
        fromdate: this.searchParams.daterange ? this.searchParams.daterange[0] : null,
        todate: this.searchParams.daterange ? this.searchParams.daterange[1] : null,
        page: this.page,
        onlynotend: this.onlyNotEnd,
      }
      this.axios
        .post("/delivery/deliverorderlist", params)
        .then(res => {
          const { values: { rows, count } } = res.data
          this.total = count;
          this.orders = rows;
        });
      this.onlyNotEnd = false;
    },
    openModal(action, order = null) {
      this.currentAction = action;
      this.dialogTitle = action === 'edit' ? '编辑订单' : '新建订单';
      this.activeCollapseNames = []; // 默认折叠
      
      if (action === 'edit' && order) {
        // 如果是编辑，则填充表单数据
        this.newOrder = Object.assign({}, order);
        
        // 如果有发货方或收货方数据，则展开对应面板
        if (order.shipper || order.shippercode || order.shipperaddress) {
          this.activeCollapseNames.push('shipper');
        }
        if (order.receiver || order.receivercode || order.receiveraddress) {
          this.activeCollapseNames.push('receiver');
        }
      } else {
        // 新建模式，重置表单
        this.newOrder = JSON.parse(JSON.stringify(this.templateOrder));
      }
      
      this.isModalOpen = true;
    },
    closeModal() {
      this.isModalOpen = false;
    },
    deleteOrder(id) {
      this.$confirm('该订单尚未结束，确定要删除吗?', '警告', {
        confirmButtonText: '确定删除',
        cancelButtonText: '我再想想',
        type: 'warning'
      }).then(() => {
        this.axios
          .post("/delivery/deletedeliverorder", { customerid: this.customerid, id })
          .then(res => {
            if (res.data.code !== 0) {
              this.$message.error(res.data.message);
              return;
            }
            this.$message.success('订单删除成功');
            this.search();
          });
      })
    },
    orderSubmit() {
      this.$refs['orderForm'].validate(async (valid) => {
        if (!valid) {
          return;
        }
        if (this.currentAction === 'edit') {
          this.axios
            .post("/delivery/updatedeliverorder", { customerid: this.customerid, ...this.newOrder })
            .then(res => {
              if (res.data.code !== 0) {
                this.$message.error(res.data.message);
                return;
              }
              this.newOrder = JSON.parse(JSON.stringify(this.templateOrder));
              this.$message.success('订单修改成功');
              this.search();
              this.closeModal();
            });
        } else {
          this.axios
            .post("/delivery/adddeliverorder", { customerid: this.customerid, ...this.newOrder })
            .then(res => {
              if (res.data.code !== 0) {
                this.$message.error(res.data.message);
                return;
              }
              this.newOrder = JSON.parse(JSON.stringify(this.templateOrder));
              this.$message.success('订单创建成功');
              this.search();
              this.mountEnterprises();
              this.closeModal();
            });
        }
      });
    },
    jumpToHistory(order) {
      this.$router.push({ path: '/dataCenter/histable', query: { deviceid: order.DeviceId, from: moment(order.StartTime).format("YYYY-MM-DD HH:mm:ss"), to: order.StopTime ? moment(order.StopTime).format("YYYY-MM-DD HH:mm:ss") : "" } });
    },
    editOrder(order) {
      this.openModal('edit', {
        id: order.Id,
        deviceid: order.DeviceId,
        starttime: order.StartTime,
        ordernumber: order.OrderNumber,
        shipper: order.Shipper,
        shippercode: order.ShipperCode,
        shipperaddress: order.ShipperAddress,
        receiver: order.Receiver,
        receivercode: order.ReceiverCode,
        receiveraddress: order.ReceiverAddress,
        shipperid: null,
        receiverid: null
      }); // 传入订单数据以编辑
    },
    setShipperInfo(value) {
      const selectedContact = this.enterprises.find(c => c.Id === value);
      if (selectedContact) {
        if (this.newOrder.shipper == '') {
          this.newOrder.shipper = selectedContact.Name;
        }
        if (this.newOrder.shippercode == '') {
          this.newOrder.shippercode = selectedContact.Code;
        }
        if (this.newOrder.shipperaddress == '') {
          this.newOrder.shipperaddress = selectedContact.Address;
        }
        this.newOrder.shipperid = null;
      }
    },
    setReceiverInfo(value) {
      const selectedContact = this.enterprises.find(c => c.Id === value);
      if (selectedContact) {
        if (this.newOrder.receiver == '') {
          this.newOrder.receiver = selectedContact.Name;
        }
        if (this.newOrder.receivercode == '') {
          this.newOrder.receivercode = selectedContact.Code;
        }
        if (this.newOrder.receiveraddress == '') {
          this.newOrder.receiveraddress = selectedContact.Address;
        }
        this.newOrder.receiverid = null
      }
    },
    handleCurrentChange(val) {
      this.page = val;
      this.search();
    },
  },
  mounted() {
    this.mountCloudDevices();
    this.mountEnterprises();
    this.search();
  }
};
</script>

<style scoped lang="scss">
.results-table {
  margin-top: 20px;
}

.search-bar {
  margin-bottom: 20px;
}

.results-table {
  margin-top: 20px;
}

.dialog-title {
  font-size: 18px;
  font-weight: bold;
  color: #333;
}

.dialog-content {
  margin-top: 20px;
}

.dialog-footer {
  text-align: right;
  margin-top: 20px;
}

.dialog-footer button {
  margin-left: 10px;
}

.form-item {
  margin-bottom: 15px;

  .el-input {
    margin-top: 5px;
  }
}

.form-item label {
  font-weight: bold;
}

.form-item .el-input,
.form-item .el-select,
.form-item .el-date-picker {
  width: 100%;
}

.el-dialog__header {
  padding: 20px;
  background-color: #f9f9f9;
}

.el-dialog__body {
  padding: 20px;
}

.custom-collapse {
  margin-top: 20px;
  border: 1px solid #EBEEF5;
  border-radius: 4px;
  
  .collapse-header {
    display: flex;
    align-items: center;
    font-weight: bold;
    
    i {
      margin-right: 8px;
      font-size: 16px;
    }
    
    .collapse-summary {
      margin-left: 10px;
      color: #909399;
      font-size: 13px;
      font-weight: normal;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      max-width: 200px;
    }
  }
}

.el-collapse-item {
  padding: 0 10px;
}

.el-form-item {
  margin-bottom: 18px;
}

.el-dialog {
  .el-dialog__body {
    padding: 20px 25px;
  }
}
</style>